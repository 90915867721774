.addClassSubject .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.addClassSubject .el-form .form-item {
  width: 100%;
  padding-left: 160px;
}
.addClassSubject .el-form .padd0 {
  padding-left: 0;
}
.addClassSubject .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addClassSubject .el-textarea .el-textarea__inner {
  resize: none;
}
.addClassSubject .paraCompleteConditionInput {
  display: inline-block;
}
.addClassSubject .paraCompleteConditionInput .el-input {
  display: inline-block;
  width: 40% !important;
  margin: 0 .625rem;
  height: 2rem;
}
 /deep/ .el-input--small .el-input__icon {
  position: relative;
  top: -0.1875rem;
}
